import * as React from 'react';
import {useContext} from 'react';
import './style.scss';
import LogoLetter from "images/logo-letter.svg";
import Badges from "components/Badges";
import SiteContext from "context/SiteContext";

const texts = {
  "tr": {
    "founder": "Kurucu:",
    "partner": "Verisi Sağlayıcıları:",
    "copyright": "Tüm hakları saklıdır",
    "email": "iletisim@paramla.com",
  },
  "en": {
    "founder": "Founder:",
    "partner": "Data Providers:",
    "copyright": "All rights reserved",
    "email": "contact@paramla.com",
  },
}

const Footer = () => {
  const site = useContext(SiteContext);

  const getClasses = (code) => {
    const list = [
      "footer-languages-lang",
      `footer-languages-${code}`,
      code === site.lang ? "footer-languages-lang-active" : "footer-languages-lang-passive"
    ]
    return list.join(' ');
  }
  const languages = [
    {
      "url": "/",
      "label": "TR",
      "classes": getClasses('tr')
    },
    {
      "url": "/en",
      "label": "EN",
      "classes": getClasses('en')
    }
  ]

  return <div id="footer" className="footer">
    <div className="footer-icon">
      <img src={LogoLetter} alt="Paramla Logo" />
    </div>
    <div className="footer-title">Paramla Finansal Danışmanlık A.Ş.</div>
    <div className="footer-address">
      Barbaros Mah, Begonya Sk, NidaKule - Batı Bl,
      <br/>
      No: 1/2, 34746 Ataşehir, İstanbul<br />
      <a href={`mailto:${texts[site.lang].email}`}>{texts[site.lang].email}</a>
    </div>
    <div className="footer-founder">
      <div className="footer-founder-col">
        {texts[site.lang].founder}
        <br/>
        <a className="footer-founder-col-ul" href="https://www.linkedin.com/in/cenkangin/">Cenk Angın</a>
      </div>
      <div className="footer-founder-col-right">
        {texts[site.lang].partner}
        <br/>
        <span>ForInvest (Foreks), CoinMarketCap, Polygon</span>
      </div>
    </div>
    <div style={{marginTop: 40}}><Badges /></div>
    <div className="footer-languages">
      {
        languages.map(language => (
          <div className={language.classes} key={language.label}>
            <a href={language.url} style={{ textDecoration: 'none' }}>{language.label}</a>
          </div>
        ))
      }
    </div>
    <div className="footer-copy">
      {texts[site.lang].copyright}<br/>
      @2021
    </div>
  </div>
}

Footer.defaultProps = {
  lang: "tr"
}

export default Footer;
