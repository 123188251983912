import * as React from "react";
import Logo from "../../images/logo-title.svg";
import "./style.scss";

const Boxed = ({children}) => {
  return <div className="boxed-container">
    <img src={Logo} />
    <div className="boxed-content">
      {children}
    </div>
  </div>
}

export default Boxed;