import * as React from "react";
import "fontsource-roboto"
import "fontsource-roboto/100.css"
import "fontsource-roboto/300.css"
import "fontsource-roboto/700.css"
import "./style.scss";
import "normalize.css"
import Footer from "../Footer";
import SiteContext from "context/SiteContext";
import {useEffect} from "react";

const Layout = ({lang, children}) => {

  useEffect(() =>{
    // @ts-ignore
    // gtag && gtag('event', 'page_view')
  }, [])

  return <div className="layout">
    <SiteContext.Provider value={{lang}}>
      <div className="content-container">
        {children}
      </div>
      <Footer/>
    </SiteContext.Provider>
  </div>
}

Layout.defaultProps = {
  lang: "tr"
}

export default Layout;