import * as React from "react";
import {useContext} from "react";
import BadgeAppStoreTR from "images/appstore-badge-tr.svg";
import BadgeAppStoreEN from "images/appstore-badge-en.svg";
import BadgeGoogleStoreTR from "images/google-play-badge-tr.png";
import BadgeGoogleStoreEN from "images/google-play-badge-en.png";
import BadgeAppGalleryTR from "images/appgallery-badge-tr.svg";
import BadgeAppGalleryEN from "images/appgallery-badge-en.png";
import SiteContext from "context/SiteContext";
import "./style.scss";

const badges = {
  "tr": {
    "apple": {
      "badge": BadgeAppStoreTR,
      "alt": "App Store'dan İndirin",
    },
    "google": {
      "badge": BadgeGoogleStoreTR,
      "alt": "Google Play'den alın",
    },
    "huawei": {
      "badge": BadgeAppGalleryTR,
      "alt": "",
    },
  },
  "en": {
    "apple": {
      "badge": BadgeAppStoreEN,
      "alt": "Download on the App Store",
    },
    "google": {
      "badge": BadgeGoogleStoreEN,
      "alt": "Get it on Google Play",
    },
    "huawei": {
      "badge": BadgeAppGalleryEN,
      "alt": "",
    }
  }
};

function gtag_report_conversion(url) {
  const callback = function () {
    if (typeof (url) != 'undefined') {
      window.location = url;
    }
  };
  window && window.gtag && window.gtag('event', 'conversion', {'send_to': 'AW-368914612/f-QpCMPU0PICELTh9K8B', 'event_callback': callback});
  return false;
}

const Badges = () => {
  const site = useContext(SiteContext);

  const generateLink = (url, type) => {
    return <a href={url} target="_blank" onClick={() => gtag_report_conversion(url)}>
      <img alt={badges[site.lang][type].alt} src={badges[site.lang][type].badge}/>
    </a>
  }

  return <div className="badges">
    <div className="badges-icon badges-icon-playstore">
      {generateLink("https://play.google.com/store/apps/details?id=com.paramla.android", "google")}
    </div>
    <div className="badges-icon badges-icon-appstore">
      {generateLink("https://apps.apple.com/us/app/id1552559235", "apple")}
    </div>
    <div className="badges-icon badges-icon-appgallery">
      {generateLink("https://appgallery.cloud.huawei.com/marketshare/app/C103853897", "huawei")}
    </div>
  </div>
}

export default Badges;
